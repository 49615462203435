const API_URL = process.env.REACT_APP_API_URL || '/api';

const getAuthHeaders = () => {
  const token = localStorage.getItem('token');
  return token ? { Authorization: `Bearer ${token}` } : {};
};

// Mock data için kategoriler ve bunlara karşılık gelen tag'ler
const categoryTagMap = {
  'Ticari Pazarlama': ['Ticari Pazarlama', 'Trade Marketing', 'TPM'],
  'Perakende': ['Perakende', 'Perakende Aktiviteleri', 'Retail'],
  'RGM': ['RGM', 'Revenue Growth Management', 'Ticari Pazarlama'],
  'TPM': ['TPM', 'Trade Promotion Management', 'Ticari Pazarlama'],
  'Dijital Dönüşüm': ['Dijital Dönüşüm', 'Digital Transformation', 'AI Powered'],
  'Veri Analitiği': ['Veri Analitiği', 'Analytics', 'Real-Time Analiz'],
  'Çözümler': ['Ocrion', 'TPM', 'RGM', 'Trade Marketing', 'Ticari Pazarlama']
};

// Mock blog posts
const blogPosts = [
  {
    id: 1,
    title: "Ocrion, Perakende Aktivitelerinde Tüketim Ürünü Sektöründeki Firmalara Nasıl Yardımcı Oluyor?",
    slug: "perakende-aktivitelerinde-ocrion",
    image: "/images/blog/perakende-aktivitelerinde-ocrion.jpg",
    date: "2024-11-20",
    readTime: "8",
    summary: "Tüketici ürünleri sektöründeki kuruluşlar için perakende aktivitelerinin optimizasyonu ve Ocrion'un sunduğu çözümler",
    content: `
      <div class="blog-content">
        <p>Tüketici ürünleri sektöründeki kuruluşlar, çeşitli nedenlerden dolayı beklenen sonuçları vermeyen perakende kanalıda aktiviteler yürütmektedir. Satış ve karlılık hedefine ulaşmak için düzenli olarak denemeler yaparlar. Ocrion, perakande kanalinda aktivite yapan firmalarin kaynaklarını daha verimli kullanarak hedeflerine ulaşmasında katma değerler sunar.</p>

        <div class="highlight">
          <p>Yapılan anketlere göre, uzmanlar bir perakende kanalında yapılan aktivitelerde aşağıdaki noktaları vurgulamaktadır. Şimdi Ocrion'un bu süreçte nasıl yardımcı olduğuna bakalım:</p>
        </div>

        <h2>Premium Markalar ve SKU Seçimi</h2>
        <p>Premium markaları kullanarak müşteri trafiği oluşturun ve zayıf markalar için değer yaratın. Ocrion, üreticilere aktivite planlama aşamasında en uygun SKU'ları (Stok Tutma Birimi) belirlemede kuruluşlara yardımcı olur. Promosyon faaliyetleri hakkında derinlemesine ve eyleme dönük, doğru kanal ve doğru SKU gibi, içgörüler sunar.</p>

        <h2>Yüksek Pazar Payı Kategorileri</h2>
        <p>Yüksek pazar payına sahip kategorileri hariç tutun. Kuruluşlar premium markalara sahiptir ve tüketiciler bu markaları zaten satın alacaktır. SKU seviyesine odaklanın veya yüksek pazar payına sahip kategorileri hariç tutun. Ocrion, kuruluşun tüm ürün yelpazesinde tam görünürlük sağlar ve aktivite tanımlanırken alt seviye kategorilerin hariç tutulmasını destekler.</p>

        <h2>Süreç Takibi</h2>
        <p>Her adımı izleyin. Kuruluşlar, bir faaliyeti planlamadan önce açıkta bekleyen promosyonları veya onay bekleyen anlaşmaları kontrol ederek kapsamlı analizler yapmalıdır. Ocrion, kapsamlı gösterge panelleri ve raporlama yetenekleriyle her hesabı 360 derece görünür hale getirir.</p>

        <h2>Bütçe Yönetimi ve Departmanlar Arası İş Birliği</h2>
        <p>Ticari pazarlama ve satış ekipleri arasında bütçe tahsisini iş birliği içinde yönetin. Fatura dışı indirimler uygularken broşür ekleri veya mağaza içi teşhirler kullanın. Kuruluşlar genellikle bütçe tahsisinin zorluğundan dolayı faaliyetleri bölmekten kaçınır.</p>
        
        <p>Tipik bir iş senaryosunda, satış ekibi yıllık anlaşmalarından satıcıya uygulanan indirim (sell-in rebate) kullanmak isterken, ticari pazarlama departmanı mağaza içi teşhir maliyetinin karşılanmasını talep eder. Ocrion, ticari promosyon sürecinde hem satış hem de pazarlama ekiplerinin iş birliği yapmasını sağlar.</p>
        
        <p>Satış ekibi, mağaza içi teşhirin maliyetini Ticari Pazarlama ekibine bildirir. Ticari pazarlama ekibi, planlanan satış artışını kontrol ederek kendi payını onaylar. Birimler arası iletişimde verimlilik yüksek oranda artar. İletişim süreleri kısalırken çeşitli kontrol noktaları konularak firmanın denetim mekanizmasına uygun hareket edilir.</p>

        <h2>Veri Analizi ve Tahminleme</h2>
        <p>Geçmiş verileri gösterin. Kuruluşlar, derin indirimlerin avantajlarını ve dezavantajlarını değerlendirmek için "ya olursa" senaryoları çalıştırmalıdır. Ocrion, aktivite planlamasında kullanıcıların dikkatini ayrıntılı analizlerle çeker. Geçmiş aktiviteleri, hem hesap seviyesinde hem de ürün ve faaliyet türü bazında gösterir. Ocrion'da hazır rapor içeriğiyle ayrıca bir rapor altyapısına gerek duymazsınız.</p>

        <h2>Entegrasyon ve Veri Yönetimi</h2>
        <p>Harici verileri yükleyin. Kuruluşlar, gerçek ROI'yi hesaplamak için dış verilere ve gerçek sevkiyat verilerine ihtiyaç duyar. Ocrion'un entegrasyon platformu, kuruluşların satış noktası (POS) ve doğrudan satış verilerini yüklemelerini sağlayarak mağaza içi aktivitelerinin gerçek performansını hesaplamalarına olanak tanır. Bu sayede Ocrion, devam eden promosyonların gerçek zamanlı analizlerini sunar. Buna ek olarak, faturanın alınmasından önce tahmini talep miktarını hesaplar.</p>

        <h2>Rekabet Analizi</h2>
        <p>Rakip fiyat ve aktivitelerin dahil edin. Ocrion üretici firmalara pazardaki diğer rakiplerinin aktivite bilgisini sunar. Aktivitelerle ilişkili rakip faaliyetleri derinlemesine gösteren hazır analiz raporları sunar. Bu sayede üretici kaynaklarının en verimli kullanarak operasyonunu yürütür.</p>

        <h2>Kullanıcı Deneyimi</h2>
        <p>Ocrion, organizasyon çalışanlarının günlük operasyonlarında zaman kazanmasını sağlayan en son teknolojiye dayalı bir kullanıcı deneyimi sunar. Kullanıcılara aktiviteleri takvim görünümünde gösterme imkanı tanır ve çakışanları tespit etmeye yardımcı olur. Aktivite takvimi, farklı açılardan görüntülenebilir: kanal, perakende müşterisi, kategori, SKU v.b. bazında.</p>
      </div>
    `,
    tags: [
      "Ocrion",
      "Revenue Growth Management",
      "Trade Marketing",
      "Ticari Pazarlama",
      "Satış ve Ticari Pazarlama",
      "Yeni Nesil Çözüm",
      "AI Powered",
      "TPM"
    ],
    category: "Çözümler",
    author: "Ocrion Ekibi"
  },
  {
    id: 2,
    title: "Satış ve Pazarlamada Verimliliği Artırmanın Yolu",
    slug: "satis-ve-pazarlamada-verimlilik-artirmanin-yolu",
    image: "/images/blog/satis-ve-pazarlamada-verimlilik-artirmanin-yolu.jpg",
    date: "2024-12-15",
    readTime: "7",
    summary: "Tüketim ürünleri sektöründe perakende aktivitelerinin verimliliğini artırmanın yolları ve Ocrion'un sunduğu çözümler",
    content: `
      <div class="blog-content">
        <p>Tüketim ürünleri sektöründeki şirketler, gelirlerinin %15 ile %25'ini perakende aktivitelerine harcıyor. Gartner'ın 2017 raporuna göre, "Tüketim ürünleri üreticileri, perakende ortaklarıyla iş birliği çerçevesinde promosyonlar ve fiyat indirimlerini yönetmek için bütçelerinin önemli bir kısmını harcamaya devam ediyor." Bunun yanı sıra, Economic Times Retail'ın verilerine göre, perakende aktivitelerinde %15'lik bir iyileşme, şirketlerin üst gelirlerini %10, operasyonel kâr marjlarını ise %3-5 oranında olumlu yönde etkileyebilir.</p>

        <div class="highlight">
          <p>Birçok tüketim ürünleri şirketi, karmaşık perakende aktivite süreçlerini yönetmek için hala Excel kullanıyor. Entegre bir çözümün eksikliği, Excel'in hala bu kadar yaygın olarak kullanılmasının başlıca nedenlerinden biri.</p>
        </div>

        <p>Şirketler, kârlı perakende aktiviteleri yapmanın en iyi yollarını arıyor, ancak bunu başaracak güçlü ve entegre araçlara sahip değiller. AMR Research'a göre, "Aktivite etkinliği, bir perakende aktivitesinin sonuçlarının artış, maliyet, kâr marjı, gelir ve hesap yönetimi faktörleri üzerinden ölçülmesidir." Bu nedenle, kullanıcıların etkili perakende aktivitelerini öngörebilmesi için tercih edilen bir çözümde belirli bilgilerin mevcut olması gerekiyor.</p>

        <h2>İdeal Bir Çözüm Neleri Kapsamalı?</h2>
        <ul>
          <li><strong>Etkili Bütçe Yönetimi:</strong> Aktivite bütçelerinin verimli bir şekilde planlanması ve takip edilmesi.</li>
          <li><strong>Veri Toplama Sürecinin Kolaylaştırılması:</strong> Kullanıcılar, farklı kaynaklardan veri toplamak için zaman harcamak yerine, aktivite analizine odaklanabilmeli.</li>
          <li><strong>Esnek ve Kullanıcı Dostu Arayüz:</strong> Gerekli bilgilerin kolayca girilebileceği, esnek ve kullanıcı dostu bir aktivite giriş ekranı.</li>
          <li><strong>İş Birliği ve Uyumluluk:</strong> Planlama sürecinde iş birliğini destekleyen ve iç denetim kurallarına uyum sağlayan bir yapı.</li>
          <li><strong>Tarihsel Veri Analizi ve Raporlama:</strong> Pazarlama ekiplerinin, geçmiş satış ve aktivite verilerini analiz ederek satış ekiplerine rehberlik etmesi.</li>
          <li><strong>Basit ve Şeffaf Ödeme Süreçleri:</strong> Fatura öncesinde beklenen tutarın hesaplanması ve ödeme süreçlerinin sorunsuz yönetimi.</li>
          <li><strong>Maliyetlerin Doğru Şekilde Dağıtılması:</strong> Tahakkuklar ve ödemelerin doğru finansal hesaplara aktarılması.</li>
        </ul>

        <h2>Ocrion ile Perakende Aktivite Yönetiminde Verimlilik</h2>
        <p>Ocrion, tüketim ürünleri şirketlerinin bu hedeflere ulaşmasına yardımcı olmak için kurumsal düzeyde, bulut tabanlı bir Yeni Nesil Çözüm sunar. Bu çözüm, tüketim ürünleri sektöründe satış ve pazarlama birimleri için perakende aktivitelerini yönetmek üzere tasarlanmıştır.</p>

        <h2>Ocrion'un Sunduğu Yenilikler</h2>
        <ul>
          <li><strong>Rakip ve Fiyat Aktivite Analizi:</strong> Etkin aktivite planlamak için rakip ve fiyat aktivitelerini sistemde takip edebilirsiniz.</li>
          <li><strong>Market Broşürleri ve Veri Keşfi:</strong> Market broşürlerinin de yer aldığı, veri keşfi ile doğru müşteri ve SKU'da aktivite yapmanız için gerekli tüm verinin tek elden yönetildiği bir platform.</li>
          <li><strong>AI Destekli Aktivite Önerileri:</strong> Yapay Zeka (AI) ile desteklenen aktivite önerileri, satış ve pazarlama ekiplerinizin karar alma süreçlerini güçlendirir.</li>
        </ul>

        <h2>Neden Ocrion?</h2>
        <ul>
          <li><strong>Uçtan Uca Entegre Çözüm:</strong> Tüm süreçlerin tek bir platformda yönetildiği, SAP gibi sistemlerle entegre bir çözüm.</li>
          <li><strong>Aktivite Öneri Sistemi:</strong> Satış ekibinizin gücüne güç katacak AI destekli aktivite öneri sistemi.</li>
          <li><strong>Güçlü Analitik:</strong> Geçmiş verileri kullanarak gelecekteki aktivitelerinizi optimize edin.</li>
          <li><strong>Kullanıcı Dostu Arayüz:</strong> Zamandan tasarruf edin ve ekiplerinizin verimliliğini artırın.</li>
          <li><strong>Bulut Tabanlı:</strong> Her yerden erişim imkanı ve güvenli veri yönetimi.</li>
        </ul>

        <div class="highlight">
          <p>Ocrion ile perakende aktivitelerinizi daha etkili ve verimli hale getirin. Detaylı bilgi ve demo talepleriniz için bizimle iletişime geçin!</p>
        </div>

        <h2>Ocrion'un Sunduğu Avantajlar</h2>
        <ul>
          <li><strong>Rekabetçi Analiz:</strong> Rakip aktivitelerini derinlemesine analiz ederek, kaynaklarınızı en verimli şekilde kullanın.</li>
          <li><strong>Veriye Dayalı Kararlar:</strong> AI destekli analizlerle, doğru müşteriye ve doğru SKU'ya odaklanın.</li>
          <li><strong>Entegre Raporlama:</strong> Tüm aktivitelerinizi tek bir platformdan takip edin ve raporlayın.</li>
        </ul>

        <p>Ocrion, tüketim ürünleri sektöründe satış ve pazarlama ekiplerinizin ihtiyaçlarını karşılamak için sürekli olarak gelişen bir platformdur. Yapay Zeka'nın perakende aktivitelerinde kullanımına dair detayları ise başka bir blog yazımızda ele alacağız.</p>
      </div>
    `,
    tags: [
      "Ocrion",
      "RGM",
      "Satış ve Pazarlama",
      "Ürün Fiyatlandırma",
      "Sales and Marketing",
      "Trade Marketing",
      "Ticari Pazarlama",
      "Perakende Aktiviteleri",
      "AI Powered",
      "Bulut Çözümler",
      "TPM"
    ],
    category: "Çözümler",
    author: "Ocrion Ekibi"
  },
  {
    id: 3,
    title: "Satış ve Pazarlamada Dijital Köprü: Verimlilik ve Büyüme",
    slug: "satis-ve-pazarlamada-dijital-kopru",
    image: "/images/blog/satis-ve-pazarlamada-dijital-kopru.jpg",
    date: "2025-01-05",
    readTime: "6",
    summary: "Satış ve ticari pazarlama ekipleri arasındaki dijital iletişimin önemi ve modern çözümler",
    content: `
      <div class="blog-content">
        <p>Günümüzde rekabetin artmasıyla birlikte, satış ve ticari pazarlama ekipleri arasındaki iletişimin hızlı ve verimli olması büyük önem taşımaktadır. Ticari pazarlama birimi, perakende aktivitelerini ve kampanyaları planlayarak satış ekibinin sahada daha etkili olmasını sağlar. Ancak, bu iki birim arasındaki iletişimde kopukluklar olması durumunda, kampanyaların etkisi azalabilir ve satış hedeflerine ulaşmak zorlaşabilir.</p>
        
        <h2>Dijital Araçların Rolü ve Excel'in Sınırları</h2>
        <p>Pek çok firma, ticari pazarlama aktivitelerini ve satış performansını Excel gibi geleneksel tablolar üzerinden yönetmeye çalışmaktadır. Ancak Excel tabanlı sistemler zamanla aşağıdaki sorunlara yol açar:</p>
        <ul>
          <li><strong>Gerçek zamanlı veri eksikliği:</strong> Satış ekibi ve ticari pazarlama birimi arasında anlık bilgi akışı sağlanamaz.</li>
          <li><strong>Veri kaybı ve hata riski:</strong> Manuel veri girişi hatalara açıktır ve büyük veri setlerinde kayıp ya da hata meydana gelebilir.</li>
          <li><strong>Takip ve raporlama gücü:</strong> Excel üzerinden kampanya performansını takip etmek ve gerçek zamanlı analiz yapmak oldukça zordur.</li>
          <li><strong>Erişim ve senkronizasyon sorunları:</strong> Ekiplerin Excel dosyalarını paylaşması ve aynı anda güncellemeler yapması sıkça veri tutarsızlıklarına neden olur.</li>
        </ul>
        <p>Bu nedenle, satış ve ticari pazarlama ekiplerinin daha etkin çalışabilmesi için entegre bir yazılım çözümü gereklidir.</p>
        
        <h2>Ticari Pazarlama İçin Dijital Çözümler</h2>
        <p>Ticari pazarlama birimleri, kampanya ve promosyon aktivitelerini optimize etmek için aşağıdaki dijital çözümleri kullanabilir:</p>
        <ul>
          <li><strong>Bulut tabanlı TPM (Trade Promotion Management) yazılımları:</strong> Kampanya planlaması, bütçe yönetimi ve satış verilerinin entegrasyonu ile operasyonel verimliliği artırır.</li>
          <li><strong>Gerçek zamanlı veri paylaşımı için mobil uygulamalar:</strong> Satış ekiplerinin sahadan anlık geri bildirim girmesini ve ticari pazarlama ekibinin buna göre strateji belirlemesini sağlar.</li>
          <li><strong>BI (Business Intelligence) Araçları:</strong> Satış ve kampanya verilerini analiz ederek gelecekteki kararları destekler.</li>
          <li><strong>Otomatik raporlama sistemleri:</strong> Satış ekibinin kampanyaların etkisini günlük olarak takip etmesini sağlar.</li>
        </ul>
        
        <h2>Hızlı İletişimin Perakende Aktivitelerindeki Etkisi</h2>
        <p>Ticari pazarlama ve satış ekipleri arasındaki hızlı iletişim, perakende aktivitelerinde çeşitli avantajlar sağlar:</p>
        <ul>
          <li><strong>Doğru stok yönetimi:</strong> Anlık veri akışı sayesinde ürün stoklarının optimize edilmesi sağlanır.</li>
          <li><strong>Promosyon etkinliğinin artması:</strong> Kampanya sürecinde satış ekibi anlık geri bildirim verebilir ve kampanyaların performansına göre hızlı aksiyon alınabilir.</li>
          <li><strong>Satış artışı:</strong> Veri odaklı kampanya planlamaları ve anlık pazar dinamiklerine uyum, satışların artmasına katkı sağlar.</li>
        </ul>
        
        <h2>Sonuç</h2>
        <p>Satış ve ticari pazarlama ekipleri arasında etkili ve hızlı iletişim sağlamak için dijital çözümlerin kullanılması kritik önem taşımaktadır. Excel gibi manuel ve statik yöntemler yerine entegre yazılımlar kullanılarak hem operasyonel verimlilik artırılabilir hem de satış performansı geliştirilebilir. Dijital enstrümanların doğru kullanılması, ticari pazarlama faaliyetlerinin etkinliğini artırarak rekabet avantajı sağlar.</p>
      </div>
    `,
    tags: [
      "Dijital Dönüşüm",
      "Satış",
      "Ticari Pazarlama",
      "TPM",
      "Verimlilik",
      "İş Süreçleri",
      "Teknoloji",
      "Ocrion"
    ],
    category: "Dijital Dönüşüm",
    author: "Ocrion Ekibi"
  },
  {
    id: 4,
    title: "Revenue Growth Management: Tüketici Ürünleri Sektöründe Değişimin Hikayesi",
    slug: "revenue-growth-management-tuketici-urunleri-sektorunde-degisimin-hikayesi",
    image: "/images/blog/revenue-growth-management.jpg",
    date: "2025-01-25",
    readTime: "8",
    summary: "RGM'in TPM'den farkları, avantajları ve modern yaklaşımların tüketici ürünleri sektöründeki etkisi",
    content: `
      <div class="blog-content">
        <p>Tüketici ürünleri sektörü, özellikle hızlı tüketim malları (FMCG) alanında, son yıllarda büyük bir dönüşüm yaşadı. Bu dönüşümün merkezinde, şirketlerin gelirlerini artırmak ve karlılıklarını optimize etmek için kullandıkları stratejiler ve araçlar yer alıyor. Eskiden "Trade Promotion Management" (TPM) olarak adlandırılan bu süreç, günümüzde "Revenue Growth Management" (RGM) olarak biliniyor. Peki, bu değişim neden kaynaklandı? RGM'in artıları nelerdir? Hangi alanlarda gelişmeye devam ediyor ve hangi zorluklarla karşı karşıya? Bu yazıda, RGM'in evrimini, avantajlarını, eksikliklerini ve doğru uygulama için gerekenleri detaylı bir şekilde ele alacağız.</p>

        <h2>Trade Promotion Management (TPM) Nedir ve Neden Yetersiz Kaldı?</h2>
        <p>TPM, tüketici ürünleri sektöründe uzun yıllar boyunca kullanılan bir yöntemdi. Temel olarak, perakendecilerle yapılan promosyon anlaşmalarını yönetmeye odaklanıyordu. Örneğin, bir ürünün belirli bir süre için indirimli satılması, satın alma başına hediye verilmesi veya raf alanı için özel anlaşmalar yapılması gibi uygulamalar TPM kapsamında değerlendiriliyordu.</p>
        <p>Ancak TPM, birkaç önemli eksiklikle karşı karşıyaydı:</p>
        <ul>
          <li><strong>Kısa Vadeli Odak:</strong> TPM, genellikle kısa vadeli satış artışlarına odaklanıyordu. Bu, şirketlerin uzun vadeli karlılık ve büyüme stratejilerini gözden kaçırmasına neden olabiliyordu.</li>
          <li><strong>Veriye Dayalı Olmama:</strong> TPM süreçleri genellikle manueldi ve veri analizine dayalı değildi. Bu da, promosyonların etkinliğinin tam olarak ölçülememesine ve yanlış kararlar alınmasına yol açıyordu.</li>
          <li><strong>Fiyatlandırma ve Maliyet Yönetimi Eksikliği:</strong> TPM, fiyatlandırma stratejilerini ve maliyet yönetimini yeterince kapsamıyordu. Bu da, şirketlerin promosyonlar nedeniyle karlılıklarını kaybetmesine neden olabiliyordu.</li>
        </ul>

        <h2>Revenue Growth Management (RGM) Nedir ve Neden Önem Kazandı?</h2>
        <p>RGM, TPM'in eksikliklerini gidermek ve daha kapsamlı bir yaklaşım sunmak amacıyla ortaya çıktı. RGM, sadece promosyonları yönetmekle kalmaz, aynı zamanda fiyatlandırma, ürün portföyü yönetimi, talep tahmini ve müşteri segmentasyonu gibi alanları da kapsar. RGM'in temel amacı, şirketlerin gelirlerini artırırken, karlılıklarını da optimize etmektir.</p>
        
        <h3>RGM'in Artıları:</h3>
        <ul>
          <li><strong>Bütünsel Yaklaşım:</strong> RGM, sadece promosyonları değil, fiyatlandırma, ürün portföyü ve müşteri segmentasyonu gibi alanları da kapsar. Bu, şirketlerin daha bütünsel bir strateji geliştirmesine olanak tanır.</li>
          <li><strong>Veriye Dayalı Karar Alma:</strong> RGM, büyük veri ve analitik araçlarını kullanarak, daha doğru ve etkili kararlar alınmasını sağlar. Bu, promosyonların etkinliğinin daha iyi ölçülmesine ve optimize edilmesine yardımcı olur.</li>
          <li><strong>Uzun Vadeli Karlılık:</strong> RGM, kısa vadeli satış artışlarından ziyade, uzun vadeli karlılık ve büyümeye odaklanır. Bu, şirketlerin sürdürülebilir bir büyüme stratejisi geliştirmesine yardımcı olur.</li>
          <li><strong>Fiyatlandırma Stratejileri:</strong> RGM, fiyatlandırma stratejilerini de kapsar. Bu, şirketlerin fiyatlandırma kararlarını daha etkili bir şekilde yönetmesine ve karlılıklarını artırmasına olanak tanır.</li>
        </ul>

        <h2>RGM'in Gelişmeye Devam Eden Alanları ve Eksiklikleri</h2>
        <ul>
          <li><strong>Veri Entegrasyonu:</strong> RGM, büyük miktarda veriye dayalıdır. Ancak, bu verilerin farklı kaynaklardan entegre edilmesi ve analiz edilmesi hala bir zorluk teşkil edebilir.</li>
          <li><strong>Yapay Zeka ve Makine Öğrenmesi:</strong> RGM, yapay zeka ve makine öğrenmesi gibi teknolojilerden büyük ölçüde yararlanır. Ancak, bu teknolojilerin uygulanması ve doğru modellerin geliştirilmesi hala bir uzmanlık gerektirir.</li>
          <li><strong>Organizasyonel Uyum:</strong> RGM, sadece bir yazılım veya araç değil, bir stratejidir. Bu nedenle, şirketlerin organizasyonel yapılarını ve süreçlerini RGM'e uyumlu hale getirmeleri gerekmektedir. Bu, bazen zorlu bir süreç olabilir.</li>
        </ul>

        <h2>Doğru RGM İçin Gereken Yazılımlar ve Manuel Uygulamaların Eksiklikleri</h2>
        <p>RGM, büyük ölçüde veri analitiği ve otomasyon araçlarına dayanır. Bu nedenle, doğru RGM uygulamaları için genellikle özel yazılımlar kullanılır. Bu yazılımlar, veri toplama, analiz etme, tahminleme ve raporlama gibi işlevleri otomatikleştirir.</p>
        
        <h3>Manuel Uygulamaların Eksiklikleri:</h3>
        <ul>
          <li><strong>Hata Oranı:</strong> Manuel uygulamalar, insan hatası riskini artırır. Özellikle büyük veri setleri üzerinde çalışırken, manuel hesaplamalar ve analizler hatalara yol açabilir.</li>
          <li><strong>Zaman Kaybı:</strong> Manuel süreçler, zaman alıcıdır ve kaynakların verimsiz kullanılmasına neden olabilir. Bu, şirketlerin hızlı kararlar almasını engelleyebilir.</li>
          <li><strong>Sınırlı Analiz Kapasitesi:</strong> Manuel uygulamalar, genellikle sınırlı bir analiz kapasitesine sahiptir. Bu da, şirketlerin daha derin ve kapsamlı analizler yapmasını engelleyebilir.</li>
        </ul>

        <h2>RGM Yazılımlarının Implementasyonunda Karşılaşılan Zorluklar</h2>
        <ul>
          <li><strong>Maliyet:</strong> RGM yazılımları, genellikle yüksek maliyetlidir. Özellikle küçük ve orta ölçekli şirketler için bu yazılımları satın almak ve uygulamak zor olabilir.</li>
          <li><strong>Entegrasyon:</strong> RGM yazılımlarının mevcut sistemlerle entegre edilmesi, bazen zorlu bir süreç olabilir. Bu, özellikle eski ve birbirinden bağımsız sistemler kullanan şirketler için geçerlidir.</li>
          <li><strong>Eğitim ve Adaptasyon:</strong> RGM yazılımlarının etkili bir şekilde kullanılabilmesi için, çalışanların eğitilmesi ve sürece adapte olması gerekmektedir. Bu, zaman ve kaynak gerektiren bir süreçtir.</li>
        </ul>

        <h2>Sonuç</h2>
        <p>Revenue Growth Management (RGM), tüketici ürünleri sektöründe bir devrim yarattı. Eskiden sadece promosyonları yönetmekle sınırlı olan Trade Promotion Management (TPM) yaklaşımı, yerini daha kapsamlı ve veriye dayalı bir strateji olan RGM'e bıraktı. RGM, şirketlere daha bütünsel bir yaklaşım sunarken, uzun vadeli karlılık ve büyüme hedeflerine ulaşmalarına yardımcı oluyor.</p>
        
        <p>Ancak, RGM'in başarılı bir şekilde uygulanabilmesi için, doğru yazılımların kullanılması ve organizasyonel uyumun sağlanması gerekiyor. Manuel uygulamaların eksiklikleri, RGM yazılımlarının önemini daha da artırıyor. Bu yazılımların implementasyonu sırasında karşılaşılan zorluklar ise, şirketlerin dikkatli bir planlama ve kaynak yönetimi yapmasını gerektiriyor.</p>
        
        <p>Bu noktada, Ocrion RGM Çözümü, sektörde öne çıkan bir platform olarak dikkat çekiyor. Ocrion, tek bir platform üzerinden aktivite planlaması, farklı kaynaklardan gelen verilerin entegrasyonu, kullanıcı dostu arayüzü ve en güncel teknolojileriyle şirketlere kapsamlı bir RGM deneyimi sunuyor. Özellikle yapay zeka (AI) destekli analiz yetenekleri sayesinde, şirketlerin daha hızlı ve doğru kararlar almasını sağlıyor. Ayrıca, entegrasyon kabiliyetiyle mevcut sistemlerle sorunsuz bir şekilde çalışarak, şirketlerin operasyonel verimliliğini artırıyor.</p>
        
        <p>Ocrion RGM Çözümü, sadece promosyon yönetimiyle sınırlı kalmayıp, fiyatlandırma stratejileri, ürün portföyü optimizasyonu ve talep tahmini gibi alanlarda da etkili bir şekilde kullanılabiliyor. Bu özellikleriyle, şirketlerin gelirlerini artırırken, karlılıklarını da maksimize etmelerine yardımcı oluyor.</p>
        
        <p>RGM, hala gelişmekte olan bir alan olarak, gelecekte daha da sofistike hale gelecek ve şirketlere daha fazla rekabet avantajı sağlayacaktır. Bu nedenle, tüketici ürünleri sektöründeki şirketlerin, Ocrion gibi kapsamlı ve yenilikçi RGM çözümlerini benimsemeleri, gelecekteki başarıları için kritik öneme sahip olacaktır. Ocrion, bu süreçte şirketlere rehberlik ederek, RGM stratejilerini en etkili şekilde uygulamalarına olanak tanıyor.</p>
      </div>
    `,
    tags: [
      "RGM",
      "TPM",
      "Ticari Pazarlama",
      "Veri Analitiği",
      "Yapay Zeka",
      "FMCG",
      "Dijital Dönüşüm",
      "Ocrion"
    ],
    category: "RGM",
    author: "Ocrion Ekibi"
  },
  {
    id: 5,
    title: "Tüketim Ürünleri Sektöründe Perakende Aktivitelerinde Yapay Zeka ile Dönüşüm Başlıyor",
    slug: "tuketim-urunleri-perakende-ai-donusumu",
    image: "/images/blog/tuketim-urunleri-perakende-ai-donusumu.png",
    date: "2025-02-17",
    readTime: "8",
    summary: "Tüketim Ürünleri (CPG) sektöründe gelişmiş analitik ve yapay zekâ teknolojileri ile perakende aktivitelerinin dönüşümü ve optimizasyonu hakkında detaylı bir inceleme.",
    content: `
      <div class="blog-content">
        <p>Tüketim Ürünleri (CPG) sektörü, son yıllarda gelişmiş analitik ve yapay zekâ (AI) teknolojileri sayesinde büyük bir dönüşüm yaşamaktadır. Bu dönüşüm, özellikle perakende aktiviteleri alanında veri odaklı karar alma mekanizmalarının etkinliğini artırarak, pazarlama ve promosyon stratejilerinin daha hassas ve verimli olmasını sağlamaktadır.</p>

        <h2>1. Perakende Aktivitelerinde Yapay Zekâ Destekli Karar Alma</h2>
        <ul>
          <li><strong>Dinamik İskonto Optimizasyonu:</strong> AI destekli algoritmalar, belirli bir perakende noktasında hangi promosyon türünün en etkili olacağını tahmin edebilir.</li>
          <li><strong>Bölgesel ve Mağaza Bazlı Fiyatlamalar:</strong> Yapay zekâ, bölgeler arasındaki fiyat elastikiyetini analiz ederek optimum fiyatlamı belirleyebilir.</li>
          <li><strong>Rekabet Analizi:</strong> AI destekli sistemler, rakip fiyat hareketlerini izleyerek şirketlere anında aksiyon alma fırsatı sunar.</li>
        </ul>

        <h2>2. Promosyon Yatırımlarının Geri Dönüşümü (ROI) Artıyor</h2>
        <p>Perakende aktivitelerinde en önemli konulardan biri perakende kanalında yapılan iskonto harcamalarının ne kadar etkili olduğunu anlamaktır. AI destekli analitik sistemler, hangi kampanyaların gerçek anlamda satış artışına neden olduğunu belirleyerek, gelecek dönemlerde en iyi yatırım kararlarını vermeyi sağlar.</p>
        <ul>
          <li><strong>Simülasyon Modelleri:</strong> Alternatif senaryolar oluşturarak farklı promosyonların olası etkilerini önceden gösterebilir.</li>
          <li><strong>SKU Bazlı Analiz:</strong> Her bir ürün için hangi aktivitenin en iyi sonucu verdiğini belirleyebilir.</li>
        </ul>

        <h2>3. Stok ve Talep Tahmini</h2>
        <p>AI, satış noktalarının taleplerini öngörerek, fazla veya eksik stok durumlarını önleyebilir. Perakendeciler ve üreticiler için büyük bir sorun olan stok optimizasyonu, yapay zekâ destekli tahminleme modelleriyle minimize edilebilir.</p>

        <h2>4. Mağaza İçi Davranış Analizi ve Köşe Taşları</h2>
        <p>AI destekli görüntü işleme teknolojileri sayesinde, tüketicilerin mağaza içindeki hareketleri takip edilerek hangi reyonların daha çok ziyaret edildiği ve hangi ürünlerin daha fazla dikkat çektiği analiz edilebilir. Bu veriler, perakende aktiviteleri ekiplerinin en iyi yerleştirme stratejilerini oluşturmasına yardımcı olur.</p>

        <h2>5. AI Destekli Rekabet Takibi ve Pazar Gözlemi</h2>
        <ul>
          <li><strong>Rakiplerin Fiyat Hareketleri:</strong> AI, rakip markaların fiyat değişikliklerini anında analiz edip, firmalara bu değişikliklere uygun yanıt verme fırsatı sunar.</li>
          <li><strong>Promosyon Sürekliliği ve Etkinliği:</strong> Rakiplerin hangi promosyon taktiklerini uyguladığını ve bunların hangi dönemlerde yapıldığını analiz ederek daha iyi planlama yapılabilir.</li>
        </ul>

        <h2>6. Ocrion ile Yeni Nesil Perakende Aktivite Yönetimi</h2>
        <p>Ocrion, perakende aktiviteleri yönetiminde pazardaki en yenilikçi çözümlerden biridir. AI destekli altyapısı sayesinde firmalara hem harcama bütçesi takibi hem aktivite planlaması hem de rakip fiyat ve aktivitelerini tek bir platformda sunar. Bu bütünleşik yapı, firmaların hızlı ve doğru kararlar almasını sağlayarak pazarda rekabet avantajı yaratır. Ocrion, geleneksel yöntemlerden farklı olarak yapay zekâ ile desteklenen analitik çözümleriyle etkin ve ölçülebilir sonuçlar sunmaktadır.</p>

        <h2>Sonuç</h2>
        <p>Gelişmiş analitik ve yapay zekâ, perakende aktiviteleri stratejilerini daha akıllı hale getirerek hem üreticiler hem de perakendeciler için daha verimli ve etkili kampanyalar oluşturma fırsatı sunuyor. Firmalar, AI destekli karar alma mekanizmalarını entegre ederek rekabet avantajını büyük ölçüde artırabilir ve tüketici davranışlarına daha hızlı ve etkin bir şekilde yanıt verebilir. Bu nedenle, tüketim ürünleri sektöründe perakende aktivitelerini yürüten firmaların AI ve analitik yetkinliklerini geliştirmesi artık bir zorunluluk haline gelmiştir.</p>
      </div>
    `,
    tags: [
      "Yapay Zeka",
      "Perakende",
      "CPG",
      "Analitik",
      "Optimizasyon",
      "AI",
      "Trade Marketing",
      "Ticari Pazarlama",
      "Ocrion"
    ],
    category: "Dijital Dönüşüm",
    author: "Ocrion Team"
  },
  {
    id: 6,
    title: "Tüketim Ürünlerinde Doğru Veriyle Etkili Aktivite Planlama",
    slug: "tuketim-urunlerinde-dogru-veriyle-etkili-aktivite-planlama",
    image: "/images/blog/tuketim-urunlerinde-dogru-veriyle-etkili-aktivite-planlama.jpg",
    date: "2025-02-16",
    readTime: "7",
    summary: "Tüketim ürünleri sektöründe perakende aktivitelerinin doğru veriyle planlanmasının önemi ve Ocrion'un sunduğu çözümler",
    content: `
      <div class="blog-content">
        <p>
          Tüketim ürünleri sektöründeki firmaların online ya da mağaza içi insert, iskonto, tadım vb. perakende aktiviteleri
          marka görünürlüğünü artırmanın ve satışları kısa vadede yukarı taşımanın en etkili yollarından biridir.
          Ancak aktivitelerin başarıya ulaşması için sadece "kampanya yapmak" yetmez;
          bu kampanyaların doğru verilerle planlanması ve uygulanması gerekir.
          Aksi halde hem kaynaklar boşa harcanır hem de beklenen tüketici etkisi sağlanamaz.
        </p>

        <h2>Doğru Aktivite için Gerekli Veriler Nelerdir?</h2>
        <ul>
          <li>Geçmiş satış ve aktivite performansı: Önceki aktivitelerin sonuçları, hangi indirim oranlarının nasıl sonuç verdiği gibi veriler.</li>
          <li>Rekabet bilgileri: Rakip markaların fiyatları, kampanya dönemleri ve uyguladıkları stratejiler.</li>
          <li>Mağaza bazlı detaylar: Hangi mağazada hangi ürünün nasıl performans gösterdiği.</li>
          <li>Bölgesel tüketici alışkanlıkları: Fiyat duyarlılığı, kampanyaya tepkiler, tüketim eğilimleri.</li>
        </ul>

        <h2>Bu Veriler Nerelerden Sağlanabilir?</h2>
        <ul>
          <li>Satış verileri: ERP sistemleri veya bayi kanalı üzerinden.</li>
          <li>Saha ekipleri: Gerçek zamanlı mağaza ziyaret raporları.</li>
          <li>Rakip fiyat takip sistemleri: Market broşürleri, e-ticaret siteleri.</li>
          <li>Tüketici içgörüleri: Sadakat kartı verileri, anketler, dijital etkileşimler.</li>
        </ul>

        <h2>Verimli Kaynak Kullanımı ve Tüketiciye Faydası</h2>
        <p>
          Doğru veriye dayalı aktivite planlama hem kaynak kullanımında maksimum verim sağlar
          hem de tüketiciye gerçekten ihtiyaç duyduğu anda doğru fiyatla ulaşmayı mümkün kılar. Bu sayede:
        </p>
        <ul>
          <li>Kampanya bütçeleri daha etkili yönetilir.</li>
          <li>Satış hedeflerine ulaşma oranı artar.</li>
          <li>Tüketici, algılanan değeri yüksek teklifler ile marka sadakati geliştirir.</li>
        </ul>

        <h2>Doğru Planlama İçin Yazılım Şart: Ocrion ile Tanışın</h2>
        <p>
          Bu süreci manuel olarak yürütmek hem zaman alıcıdır hem de hata payı yüksektir.
          Ocrion'un Perakende Aktiviteleri Planlama Çözümü, rakip fiyat verilerini ve geçmiş aktivite performanslarını
          bir araya getirerek kampanya planlamayı doğru, hızlı ve kolay hale getirir.
        </p>
        <p>
          Üstelik Ocrion, Yapay Zekâ (AI) destekli öneri sistemiyle, yoğun iş temposunda çalışan ticari pazarlama ekiplerinin
          yükünü ciddi oranda azaltır. Sistem, veriler üzerinden en uygun kampanya önerilerini sunarak ekiplerin hedeflerine
          daha hızlı ve isabetli şekilde ulaşmasını sağlar.
        </p>

        <div class="highlight">
          <p>
            Kampanyalarınızı tahmine değil, veriye dayandırın. Doğru veriyle desteklenen bir fiyat stratejisi,
            hem iş sonuçlarını hem de tüketici memnuniyetini en üst seviyeye taşır.
          </p>
        </div>
      </div>
    `,
    tags: [
      "Ocrion",
      "Ticari Pazarlama",
      "Perakende",
      "Veri Analitiği",
      "AI Powered",
      "TPM",
      "RGM",
      "Trade Marketing"
    ],
    category: "Ticari Pazarlama",
    author: "Ocrion Ekibi"
  },
  {
    id: 7,
    title: "Operasyonel Süreçlerde Real-Time Analiz İhtiyacı ve Çözümler",
    slug: "operasyonel-sureclerde-real-time-analiz",
    image: "/images/blog/real-time-analiz.jpg",
    date: "2025-02-10",
    readTime: "7",
    summary: "Modern işletmelerde gerçek zamanlı veri analizi ihtiyacı ve Ocrion'un sunduğu yenilikçi çözümler",
    content: `
      <div class="blog-content">
        <h1>Operasyonel Süreçlerde Real-Time Analiz İhtiyacı ve Çözümler</h1>

        <div class="section">
          <h2>Mevcut Durum ve Zorluklar</h2>
          <p>Günümüz işletmeleri, operasyonlarını yönetmek için çeşitli yazılım çözümleri kullanmaktadır:</p>
          <ul>
            <li>ERP sistemleri (satış, fatura, finans, stok yönetimi)</li>
            <li>Raporlama araçları</li>
            <li>İş zekası çözümleri</li>
          </ul>

          <p>Ancak bu sistemler arasında gerçek zamanlı veri akışı ve analiz konusunda önemli eksiklikler bulunmaktadır. Bunun başlıca nedenleri:</p>
          <ol>
            <li>Farklı tedarikçilerden gelen çözümlerin entegrasyon zorlukları</li>
            <li>Lisanslama kısıtlamaları ve maliyetler</li>
            <li>Sistemler arası veri formatı uyumsuzlukları</li>
            <li>Real-time veri işleme kapasitesi eksikliği</li>
          </ol>
        </div>

        <div class="section">
          <h2>Real-Time Operasyonel Analiz Araçlarının Sağlayacağı Faydalar</h2>
          
          <h3>Satış ve Pazarlama Alanında:</h3>
          <ul>
            <li>Anlık satış trendlerinin tespiti</li>
            <li>Stok seviyelerine göre dinamik fiyatlandırma</li>
            <li>Müşteri davranışlarına göre hızlı aksiyonlar</li>
            <li>Kampanya performansının anlık takibi ve optimizasyonu</li>
          </ul>

          <h3>Operasyonel Verimlilikte:</h3>
          <ul>
            <li>Kaynak kullanımının optimize edilmesi</li>
            <li>Tedarik zinciri verimliliğinin artırılması</li>
            <li>Fire ve kayıpların minimize edilmesi</li>
            <li>Operasyonel maliyetlerin düşürülmesi</li>
          </ul>

          <h3>Karar Verme Süreçlerinde:</h3>
          <ul>
            <li>Veri odaklı hızlı karar alma</li>
            <li>Proaktif risk yönetimi</li>
            <li>Pazar değişikliklerine hızlı adaptasyon</li>
            <li>Rekabet avantajı sağlama</li>
          </ul>
        </div>

        <div class="section">
          <h2>Ocrion Revenue Growth Management: Yeni Nesil Çözüm</h2>
          <p>Ocrion, bahsedilen zorlukları aşmak ve işletmelere real-time operasyonel analiz imkanı sunmak için geliştirilmiş yenilikçi bir çözümdür.</p>

          <div class="highlight">
            <h3>Ocrion'un Temel Özellikleri:</h3>
            <ol class="feature-list">
              <li>
                <span class="feature-title">Real-Time Veri İşleme ve Analiz</span>
                <ul>
                  <li>Satış verilerinin anlık analizi</li>
                  <li>Müşteri davranışlarının gerçek zamanlı izlenmesi</li>
                </ul>
              </li>
              <li>
                <span class="feature-title">Operasyonel Aksiyon Önerileri</span>
                <ul>
                  <li>Perakende nokta bazında aktivite önerileri</li>
                  <li>Ürün bazlı satış stratejileri</li>
                  <li>Dinamik fiyatlandırma tavsiyeleri</li>
                </ul>
              </li>
              <li>
                <span class="feature-title">Otomatik Execution</span>
                <ul>
                  <li>Önerilen aksiyonların hızlı uygulanması</li>
                  <li>Sonuçların anlık takibi</li>
                  <li>Performans ölçümü ve optimizasyon</li>
                </ul>
              </li>
            </ol>
          </div>

          <h3>Ocrion'un Sağladığı Katma Değer:</h3>
          <ul>
            <li>Satış ekiplerinin verimliliğinde artış</li>
            <li>Daha etkili perakende aktiviteleri yönetimi</li>
            <li>Karlılık artışı</li>
            <li>Müşteri memnuniyetinde iyileşme</li>
          </ul>
        </div>

        <div class="section">
          <h2>Sonuç</h2>
          <p>Ocrion Revenue Growth Management, modern işletmelerin ihtiyaç duyduğu real-time operasyonel analiz ve aksiyon alma özelliklerini tek bir platformda birleştiren yenilikçi bir çözümdür. Özellikle satış ve pazarlama alanında sağladığı real-time veri analizi ve otomatik execution özellikleriyle, işletmelerin operasyonel verimliliğini ve karlılığını artırmada önemli bir rol oynamaktadır.</p>
        </div>
      </div>
    `,
    tags: [
      "Real-Time Analiz",
      "Operasyonel Verimlilik",
      "Veri Analitiği",
      "RGM",
      "Dijital Dönüşüm",
      "Ocrion",
      "AI Powered",
      "İş Süreçleri"
    ],
    category: "Veri Analitiği",
    author: "Ocrion Ekibi"
  }
];

// Get all blog posts
const getAllPosts = () => {
  return blogPosts;
};

// Get a single blog post by slug
const getPostBySlug = (slug) => {
  return blogPosts.find(post => post.slug === slug) || null;
};

// Get a single blog post by ID
const getPostById = (id) => {
  return blogPosts.find(post => post.id === parseInt(id)) || null;
};

// Get all categories from blog posts
const getCategories = () => {
  const categories = blogPosts.map(post => post.category);
  return [...new Set(categories)]; // Remove duplicates
};

// Get all tags from blog posts
const getTags = () => {
  const tags = blogPosts.flatMap(post => post.tags);
  return [...new Set(tags)]; // Remove duplicates
};

// Get posts by category
const getPostsByCategory = (category) => {
  return blogPosts.filter(post => post.category === category);
};

// Get posts by tag
const getPostsByTag = (tag) => {
  return blogPosts.filter(post => post.tags.includes(tag));
};

// Search posts
const searchPosts = (query) => {
  const searchTerm = query.toLowerCase();
  return blogPosts.filter(post => 
    post.title.toLowerCase().includes(searchTerm) || 
    post.content.toLowerCase().includes(searchTerm) ||
    post.summary.toLowerCase().includes(searchTerm) ||
    post.tags.some(tag => tag.toLowerCase().includes(searchTerm))
  );
};

// Get related posts (based on shared tags and category)
const getRelatedPosts = (postId, limit = 3) => {
  const currentPost = getPostById(postId);
  if (!currentPost) return [];

  return blogPosts
    .filter(post => post.id !== currentPost.id)
    .map(post => {
      // Score based on shared tags and category
      const sharedTags = post.tags.filter(tag => currentPost.tags.includes(tag));
      const score = (sharedTags.length * 2) + (post.category === currentPost.category ? 3 : 0);
      return { ...post, score };
    })
    .sort((a, b) => b.score - a.score)
    .slice(0, limit);
};

// Add the getPosts method with pagination, filtering, and sorting
const getPosts = ({ page = 1, limit = 8, category = '', tag = '', search = '', sort = 'newest' }) => {
  let filteredPosts = [...blogPosts];
  
  // Filter by category if provided
  if (category) {
    filteredPosts = filteredPosts.filter(post => post.category === category);
  }
  
  // Filter by tag if provided
  if (tag) {
    filteredPosts = filteredPosts.filter(post => post.tags.includes(tag));
  }
  
  // Filter by search query if provided
  if (search) {
    const searchTerm = search.toLowerCase();
    filteredPosts = filteredPosts.filter(post => 
      post.title.toLowerCase().includes(searchTerm) || 
      post.content.toLowerCase().includes(searchTerm) ||
      post.summary.toLowerCase().includes(searchTerm) ||
      post.tags.some(tag => tag.toLowerCase().includes(searchTerm))
    );
  }
  
  // Sort posts based on sort parameter
  if (sort === 'newest') {
    filteredPosts.sort((a, b) => new Date(b.date) - new Date(a.date));
  } else if (sort === 'oldest') {
    filteredPosts.sort((a, b) => new Date(a.date) - new Date(b.date));
  } else if (sort === 'title_asc') {
    filteredPosts.sort((a, b) => a.title.localeCompare(b.title));
  } else if (sort === 'title_desc') {
    filteredPosts.sort((a, b) => b.title.localeCompare(a.title));
  }
  
  // Calculate pagination
  const startIndex = (page - 1) * limit;
  const endIndex = startIndex + limit;
  const paginatedPosts = filteredPosts.slice(startIndex, endIndex);
  
  return {
    posts: paginatedPosts,
    total: filteredPosts.length
  };
};

// Create the blogService object first
const blogService = {
  getAllPosts,
  getPostBySlug,
  getPostById,
  getCategories,
  getTags,
  getPostsByCategory,
  getPostsByTag,
  searchPosts,
  getRelatedPosts,
  categoryTagMap,
  getPosts
};

// Then export everything including the blogService
export {
  getAllPosts,
  getPostBySlug,
  getPostById,
  getCategories,
  getTags,
  getPostsByCategory,
  getPostsByTag,
  searchPosts,
  getRelatedPosts,
  categoryTagMap,
  blogService,
  getPosts
};

export default blogService;